<template>
  <div class="device-info">
      <div class="container-img" v-if="img != undefined">
        <b-avatar
          size="40px"
          :src="img"
        />
      </div>
      <div class="device-text-container" v-if="!relativeTime">
        <span class="main-info" :class="classValue">{{value}}</span>
        <span class="subtext-info" :class="classDescription">{{description}}</span>
      </div>
  </div>
</template>
<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar
  },
  props: {
    img: String,
    value: String,
    description: String,
    classValue: String,
    classDescription: String
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/listItem.scss";
</style>
