<template>
  <div>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" /> Mis Datos
        </template>
        <b-card>
          <b-row align-h="end">
            <b-col>
              <b-button
                variant="flat-primary"
                class="btn-icon float-right"
                @click="editProfile = true"
                v-if="!editProfile"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="flat-primary"
                class="btn-icon float-right"
                @click="editProfile = false"
                v-if="editProfile"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-form
            class=""
            @submit.prevent
            v-if="editProfile"
          >
          <validation-observer ref="userValidation">
            <b-row class="mb-1">
              <b-col cols="12" md="6" class="text-center">
                <b-avatar
                  size="90px"
                  class="shadow"
                  :src="editUser.avatar"
                />
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" md="6" class="text-center">
                <input type="file" id="file" ref="file" @change="handleFileUpload()" class="hidden"/>
                <b-button variant="primarySpore" class="mt-1" @click="$refs.file.click()">
                  Cambiar imagen
                </b-button>
                <b-button variant="primarySpore" class="mt-1 ml-1" @click="saveImage()">
                  Guardar
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Nombre(s)" label-for="name">
                  <validation-provider #default="{ errors }" name="name" rules="required">
                    <b-form-input id="name" v-model="editUser.name" :state="errors.length > 0 ? false:null" name="name" placeholder="Nombre(s)"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Apellido(s)" label-for="lastName">
                  <validation-provider #default="{ errors }" name="lastName" rules="required">
                    <b-form-input id="lastName" v-model="editUser.lastName" :state="errors.length > 0 ? false:null" name="lastName" placeholder="Apellido(s)"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Teléfono" label-for="phone" >
                  <validation-provider #default="{ errors }" name="phone" rules="required|digits:10">
                    <b-form-input id="phone" v-model="editUser.phone" :state="errors.length > 0 ? false:null" name="phone" placeholder="Teléfono"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" offset-md="6">
                <b-button type="submit" variant="primarySpore" block @click="validationForm" class="mt-3">
                  Guardar
                </b-button>
              </b-col>
            </b-row>
            </validation-observer>
          </b-form>
          <div v-else>
            <avatarItem :img="user.avatar" :value="user.name" description="Nombre Usuario"/>
            <valueItem class="mt-1" :value="user.phone" description="Teléfono" img="phone"/>
          </div>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" /> Cambiar contraseña
        </template>
        <b-card>
          <b-form class="" @submit.prevent>
            <validation-observer ref="passwordValidation">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Contraseña actual" label-for="oldPassword">
                  <validation-provider #default="{ errors }" name="password" rules="required">
                    <b-form-input id="oldPassword" v-model="changePassword.oldPassword" :state="errors.length > 0 ? false:null" name="password" placeholder="Contraseña" type="password"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Nueva Contraseña" label-for="password">
                  <validation-provider #default="{ errors }" name="password" rules="required|password" >
                    <b-form-input id="password" v-model="changePassword.password" :state="errors.length > 0 ? false:null" name="password" placeholder="Contraseña" type="password"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Confirmar contraseña" label-for="confirmPassword">
                  <validation-provider #default="{ errors }" name="confirmPassword" rules="required|confirmed:password">
                    <b-form-input id="confirmPassword" v-model="changePassword.confirmPassword" :state="errors.length > 0 ? false:null" name="confirmPassword" placeholder="Contraseña" type="password"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
            <b-col cols="12" md="6" offset-md="6">
                <b-button type="submit" variant="primarySpore" block @click="passwordValidation" class="mt-3">
                  Guardar
                </b-button>
              </b-col>
            </b-row>
            </validation-observer>
          </b-form>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" /> Cambiar NIP
        </template>
        <b-card>
          <b-form class="" @submit.prevent>
            <validation-observer ref="nipValidation">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="NIP" label-for="NIP">
                  <validation-provider #default="{ errors }" name="NIP" rules="required" >
                    <b-form-input id="NIP" v-model="NIP" :state="errors.length > 0 ? false:null" name="NIP" autocomplete="off" placeholder="NIP" maxlength="4" type="password"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
            <b-col cols="12" md="6" offset-md="6">
                <b-button type="submit" variant="primarySpore" block @click="nipValidation" class="mt-3">
                  Guardar
                </b-button>
              </b-col>
            </b-row>
            </validation-observer>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import avatarItem from '@core/spore-components/avatarItem/avatarItem.vue'
import request from '@/libs/request/index'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BTabs,
  BTab,
  BAvatar
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

/* eslint-disable quotes */
import { hashString } from "@/libs/session/index"

export default {
  components: {
    valueItem,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    BAvatar,
    avatarItem
  },
  data () {
    return {
      user: {
        avatar: '',
        name: '',
        role: '',
        phone: ''
      },
      editUser: {
        avatar: '',
        name: '',
        lastName: '',
        phone: ''
      },
      response: {},
      changePassword: {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      required,
      edit: false,
      infoEdit: {},
      bloodTypes: [
        { value: 'A+', text: 'A+' },
        { value: 'A-', text: 'A-' },
        { value: 'B+', text: 'B+' },
        { value: 'B-', text: 'B-' },
        { value: 'AB+', text: 'AB+' },
        { value: 'AB-', text: 'AB-' },
        { value: 'O+', text: 'O+' },
        { value: 'O-', text: 'O-' }
      ],
      editProfile: false,
      userRole: {
        TP: this.$t('Labels.Carrier'),
        TA: this.$t('Labels.Admin'),
        TO: this.$t('Labels.Operator')
      },
      file: '',
      NIP: ''
    }
  },
  methods: {
    async getProfile () {
      const params = {
        url: 'users/avatar/load',
        method: 'GET'
      }
      await request(params).then(data => {
        this.user.name = data.data.data.firstName + ' ' + data.data.data.lastName
        this.editUser.name = data.data.data.firstName
        this.editUser.lastName = data.data.data.lastName
        this.user.phone = data.data.data.phone
        this.editUser.phone = data.data.data.phone
        this.user.avatar = 'data:image/jpeg;base64,' + data.data.data.avatar
        this.editUser.avatar = 'data:image/jpeg;base64,' + data.data.data.avatar
      })
    },
    async save () {
      const params = {
        url: 'users',
        method: 'PUT',
        params: {
          phone: this.editUser.phone,
          firstName: this.editUser.name,
          lastName: this.editUser.lastName
        }
      }
      await request(params).then(response => {
        this.user.phone = this.editUser.phone.toString()
        this.user.name = this.editUser.name + ' ' + this.editUser.lastName
        this.editProfile = false
        this.$store.commit('session/UPDATE_USER_INFO', {
          displayName: this.editUser.name + ' ' + this.editUser.lastName
        })
      })
    },
    validationForm () {
      this.$refs.userValidation.validate().then(success => {
        if (success) {
          this.save()
        }
      })
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      const reader = new FileReader()

      // Leemos el archivo subido y se lo pasamos a nuestro fileReader
      reader.readAsDataURL(this.$refs.file.files[0])

      // Le decimos que cuando este listo ejecute el código interno
      const app = this
      reader.onload = function () {
        app.editUser.avatar = reader.result
      }
    },
    logout () {
      localStorage.setItem('avatar', '')
      this.$cookies.remove('session')

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push('/')
    },
    async updatePassword () {
      const params = {
        url: 'users/password/update',
        method: 'PUT',
        params: {
          oldPass: this.changePassword.oldPassword,
          newPass: this.changePassword.password,
          reNewPass: this.changePassword.confirmPassword
        }
      }
      await request(params).then(response => {
        this.$refs.passwordValidation.reset()
        this.changePassword = {
          oldPassword: '',
          password: '',
          confirmPassword: ''
        }
        this.editProfile = false
        this.logout()
      })
    },
    async updateNip () {
      const params = {
        url: 'users/nip/update',
        method: 'PUT',
        params: {
          nip: hashString(this.NIP)
        }
      }
      await request(params).then(response => {
        this.$refs.nipValidation.reset()
        this.NIP = ''
        this.editProfile = false
      })
    },
    passwordValidation () {
      this.$refs.passwordValidation.validate().then(success => {
        if (success) {
          this.updatePassword()
        }
      })
    },
    nipValidation () {
      this.$refs.nipValidation.validate().then(success => {
        if (success) {
          this.updateNip()
        }
      })
    },
    async saveImage () {
      const form = new FormData()
      form.append('file', this.file)
      const params = {
        url: 'users/avatar/update',
        method: 'PUT',
        params: form
      }
      await request(params).then(response => {
        this.user.avatar = this.editUser.avatar.toString()
        localStorage.setItem('avatar', this.editUser.avatar.toString())
      })
    }
  },
  created () {
    this.user.role = this.userRole[this.$store.state.session.AppActiveUser().userRole]
    this.getProfile()
  }
}
</script>
<style lang="scss" scoped>
</style>
